<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo" v-if="isMainInstance">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Moovee
        </h2>
      </b-link>
      <b-link class="brand-logo brand-logo-full" v-else>
        <b-img :src="require('@/assets/images/logo/logo-v2.png')" alt="logo" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 d-none d-lg-flex">
            Приключения начинаются здесь 🚀
          </b-card-title>
          <b-card-text class="mb-2 d-none d-lg-flex">
            Создавайте интерактивные видео квесты!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{invalid}">
            <b-form
              class="auth-register-form mt-6 mt-lg-2"
              @submit.prevent="register"
            >
              <!-- username -->

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Пароль">
                <validation-provider
                  #default="{ errors }"
                  name="Пароль"
                  vid="password"
                  rules="required|min:6"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    size="lg"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  Я согласен с уловиями
                  <b-link>политики приватности</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
                size="lg"
              >
                Регистрация
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Есть аккаунт?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Авторизируйтесь</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
localize("ru", ru);

import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email, min } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";

import { db, fb } from "@/main";
import firebase from "firebase/compat/app";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      userEmail: "",
      password: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
      min,
    };
  },
  metaInfo() {
    return {
      title: "moovee - Регистрация",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    isMainInstance() {
      return !process.env.VUE_APP_ENV || process.env.VUE_APP_ENV !== "white";
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          const expiresIn = 604800;
          const expireTimestamp = firebase.firestore.Timestamp.now().toDate();
          expireTimestamp.setSeconds(expireTimestamp.getSeconds() + expiresIn);
          fb.auth()
            .createUserWithEmailAndPassword(this.userEmail, this.password)
            .then((userCredentials) => {
              const user = fb.auth().currentUser;
              if (this.isMainInstance) {
                user.sendEmailVerification().then(() => {
                  db.collection("users")
                    .doc(user.uid)
                    .set({
                      email: this.userEmail,
                      status: true,
                      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                      tariffAt: firebase.firestore.FieldValue.serverTimestamp(),
                      tariff: "Demo",
                      tariffTill: firebase.firestore.Timestamp.fromDate(
                        expireTimestamp
                      ),
                    })
                    .then(() => {
                      const userData = {
                        ...userCredentials,
                        role: "creator",
                        status: true,
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        tariffAt: firebase.firestore.FieldValue.serverTimestamp(),
                        tariff: "Demo",
                        tariffTill: firebase.firestore.Timestamp.fromDate(
                          expireTimestamp
                        ),
                      };
                      localStorage.setItem(
                        "userData",
                        JSON.stringify(userData)
                      );
                      localStorage.setItem("userId", userCredentials.user.uid);
                      const ability = [
                        {
                          action: "manage",
                          subject: "all",
                        },
                      ];
                      this.$ability.update(ability);
                      this.$router.push("/verification");
                    });
                });
              } else {
                db.collection("users")
                  .doc(user.uid)
                  .set({
                    email: this.userEmail,
                    status: true,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    tariffAt: firebase.firestore.FieldValue.serverTimestamp(),
                    tariff: "Demo",
                    tariffTill: firebase.firestore.Timestamp.fromDate(
                      expireTimestamp
                    ),
                  })
                  .then(() => {
                    const userData = {
                      ...userCredentials,
                      role: "creator",
                      status: true,
                      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                      tariffAt: firebase.firestore.FieldValue.serverTimestamp(),
                      tariff: "Demo",
                      isConfirmed: true,
                      tariffTill: firebase.firestore.Timestamp.fromDate(
                        expireTimestamp
                      ),
                    };
                    localStorage.setItem("userData", JSON.stringify(userData));
                    localStorage.setItem("userId", userCredentials.user.uid);
                    const ability = [
                      {
                        action: "manage",
                        subject: "all",
                      },
                    ];
                    this.$ability.update(ability);
                    this.$router.push("/projects");
                  });
              }
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
